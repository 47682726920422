/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：index.js
 * Date: 2020/8/18 上午10:42
 * Author:qnnp
 */
import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React              from 'react';
import ReactDOM           from 'react-dom';
import './index.css';
import App                from './App';
import * as serviceWorker from './serviceWorker';
import {ConfigProvider}   from "antd";
import zhCN               from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';

ReactDOM.render(
	// <React.StrictMode>
	<ConfigProvider locale={zhCN}>
		<App />
	</ConfigProvider>
	// </React.StrictMode>
	,
	document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
