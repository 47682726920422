/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：PostListBox.js
 * Date: 2020/8/19 下午12:59
 * Author:qnnp
 */
import React, {
	useEffect,
	useState
}             from "react";
import {Link} from "react-router-dom";
import moment from "moment";
import './Style/PostListBox.css'
import Conf   from "./Conf";

function PostListBox(props) {
	let [Theme, setTheme] = useState(<span />)
	useEffect(() => {
		switch (props.theme) {
			//公告信息列表Box
			case 'ggxx':
				setTheme(
					<div className={'PostListBox ThemeGgxx'} id={props.id}>
						<h5 className={'PostListBoxTitle'}>
							{props.title}
							<Link to={props.to} className={'More'} style={{
								display: 'block',
								float  : 'right'
							}}>
								more >>
							</Link>
						</h5>
						<ul
							onClick={() => {window.scrollTo(0, 0)}} style={{
							padding  : 0,
							margin   : 0,
							listStyle: "none"
						}}>
							{props.data.map(
								item =>
									<li key={item.id}
									    style={{
										    lineHeight: '200%',
										    position  : "relative"
									    }}
									    className={'ListItem'}>
										<Link
											title={'点击访问：' + item.title + '\n'
											       + '发布时间：' + moment(item.addTime)
												       .format('YYYY年MM月DD日')}
											to={'/Post/' + Conf().PostType[props.title] + '/' + item.id}
											className={'ListLink'}
											onClick={e => {
												if (!!item.isUrl) {
													window.open(item.url)
													e.preventDefault()
												}
											}}
										>
											{item.title}
										</Link>
										<span className={'ListDate'}>[{moment(item.addTime)
											.format('MM/DD')}]</span>
										{
											moment(item.addTime)
												.isAfter(moment()
													         .subtract(30, "days"))
											&& <span style={{
												position       : "absolute",
												right          : 0,
												top            : 0,
												marginTop      : "0px",
												marginRight    : "-20px",
												transform      : "rotate(30deg)",
												backgroundColor: "red",
												lineHeight     : "100%",
												padding        : "1px 4px",
												fontSize       : "12px",
												color          : "white",
												fontWeight     : "bold",
												borderRadius   : "3px",
											}}>new!</span>
										}
									</li>
							)}
						</ul>
					</div>
				)
				break;
			//	大列表
			case 'Big':
				setTheme(
					<div className={'PostListBox ThemeBig'}>
						<h5 className={'PostListBoxTitle'}>
							{props.title}
							<Link to={props.to} className={'More'} style={{
								display: 'block',
								float  : 'right'
							}}>
								more >>
							</Link>
						</h5>
						<ul
							onClick={() => {window.scrollTo(0, 0)}} style={{
							padding       : 0,
							margin        : 0,
							listStyle     : "none",
							justifyContent: "space-between",
							display       : "flex",
							flexWrap      : "wrap"
						}}>
							{props.data.map(
								item =>
									<li key={item.id} style={{
										lineHeight: '200%',
										width     : Conf().MobileDevice ? '47%' : '100%'
									}} className={'ListItem'}>
										<Link
											title={'点击访问：' + item.title + '\n'
											       + '发布时间：' + moment(item.addTime)
												       .format('YYYY年MM月DD日')}
											to={'/Post/' + Conf().PostType[props.title] + '/' + item.id}
											className={'ListLink'}
											onClick={e => {
												if (!!item.isUrl) {
													window.open(item.url)
													e.preventDefault()
												}
											}}
										>
											<span className={'ListDate'}>[{moment(item.addTime)
												.format('MM/DD')}]</span>&emsp;
											{item.title}
										</Link>
									
									</li>
							)}
						</ul>
					</div>
				)
				break;
			//	圆点小列表
			case 'Dot':
				setTheme(
					<div className={'PostListBox ThemeDot'}>
						<h5 className={'PostListBoxTitle'}>
							{props.title}
							<Link to={props.to} className={'More'} style={{
								display: 'block',
								float  : 'right'
							}}>
								more >>
							</Link>
						</h5>
						<ul
							onClick={() => {window.scrollTo(0, 0)}}
							style={{
								padding       : 0,
								margin        : 0,
								listStyle     : "none",
								justifyContent: "space-between",
								display       : "flex",
								flexWrap      : "wrap"
							}}
						>
							{props.data.map(
								item =>
									<li key={item.id} style={{lineHeight: '200%'}} className={'ListItem'}>
										<Link
											title={'点击访问：' + item.title + '\n'
											       + '发布时间：' + moment(item.addTime)
												       .format('YYYY年MM月DD日')}
											to={'/Post/' + Conf().PostType[props.title] + '/' + item.id}
											className={'ListLink'}
											onClick={e => {
												if (!!item.isUrl) {
													window.open(item.url)
													e.preventDefault()
												}
											}}
										>
											{/*<span className={'ListDate'}>[{moment(item.addTime)*/}
											{/*	.format('MM/DD')}]</span>&emsp;*/}
											<span style={{fontSize: '20px'}}>●</span>&emsp;{item.title}
										</Link>
									
									</li>
							)}
						</ul>
					</div>
				)
				break;
			default:
				setTheme(<div style={{width: '100%'}}>
					<h5>
						{props.title}
						<Link to={props.to} style={{
							display: 'block',
							float  : 'right'
						}}>
							more >>
						</Link>
					</h5>
					<ul
						onClick={() => {window.scrollTo(0, 0)}} style={{
						padding  : 0,
						margin   : 0,
						listStyle: "none"
					}}>
						{props.data.map(
							item =>
								<li key={item.id} style={{
									lineHeight: '200%'
								}}>
									<span>[{moment(item.addTime)
										.format('MM/DD')}]</span>
									{item.title}
								</li>
						)}
					</ul>
				</div>)
		}
	}, [])
	return <div
		style={{
			width         : "100%",
			display       : "flex",
			justifyContent: "center"
		}}
	>{Theme}</div>
}
export default PostListBox;
